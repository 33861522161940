.circular-progress-bar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    height: 56px;
    width: 56px;
    border-radius: 50%;
    background-color: #fff;
  }

  .progress-label {
    z-index: 1;
  }
}
