.helpline-record {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  padding: 2rem;
  min-height: 100vh;
  padding-bottom: 150px;

  .record-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1.5rem 0rem 1.5rem;
    background-color: #fbfcfe;
    border: 1px solid #dde4f0;
    border-radius: 8px;
    // TODO remove duplicates
    .top-section {
      width: 100%;
      padding-bottom: 1.5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      .top-left-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;

        .client-avatar {
          height: 3rem;
          width: 3rem;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .basic-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          ul {
            list-style: none;
            overflow: hidden;
            padding: 0;
            margin: 0;
            display: inline-flex;
            text-align: center;
            list-style-position: inside;
            height: 25px;

            li {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              margin-right: 0.5rem;

              &::before {
                font-size: 32px;
                content: '\2022';
                color: rgba(99, 108, 115, 0.24);
                font-weight: bold;
                display: inline-block;
                margin-right: 0.5rem;
              }
            }
          }
        }
      }
    }
    .details-section {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      &:not(:last-child) {
        border-bottom: 0.5px solid #c8cbd9;
      }

      .details-type-header {
        margin: 1.5rem 0 1rem 0;
      }

      .details-section-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1rem;
        gap: 4.25rem;

        &.equal-spaced {
          justify-content: space-between;
          gap: 0;
        }

        .details-item {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 0.25rem;

          &.row-type {
            flex-direction: row;
          }

          &.column-type {
            flex-direction: column;
          }

          .row-values {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 0.25rem;
          }
        }

        .full-width-item {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 1rem;
          position: relative;
          box-sizing: border-box;
          padding-bottom: 2rem;

          &.info {
            padding: 0.75rem 1rem;
            background: #f0f2f8;
            border: 1px solid #dde4f0;
            border-radius: 8px;
          }

          .footer-content {
            position: absolute;
            bottom: 0px;
            right: 24px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          }
        }
      }
    }
  }

  .comments-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .new-comment {
      margin-top: 1rem;
      width: 100%;
      height: 200px;
    }

    .comment-item {
      margin-top: 1rem;
      width: 100%;
      background-color: #fbfcfe;
      border: 1px solid #dde4f0;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 1.5rem 1.5rem 2rem 1.5rem;
      position: relative;

      .footer-content {
        position: absolute;
        bottom: 1rem;
        right: 24px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .comments-action {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      gap: 0.5rem;
    }
  }
}
