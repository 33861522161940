.assign-container {
  width: 600px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.25rem;
  position: relative;

  img.close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
  }

  .assign-headers {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 0 1rem;
    box-sizing: border-box;
    gap: 1rem;
  }

  .clients-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 1rem 75px 1rem;
    box-sizing: border-box;
    height: 400px;
    overflow-y: auto;
    box-sizing: border-box;
    position: relative;

    .empty-results {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .footer-container {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: #fbfcfe;
    height: 73px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #dde4f0;
    padding: 1rem;
    box-sizing: border-box;
  }
}
