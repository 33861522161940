.phq-modal-container {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .phq-score-container {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .phq-score-category {
    width: 70%;
    padding: 10px 0;
    justify-content: flex-start;
    display: flex;
    gap: 20px;
    section {
      flex: 0 0 auto;
      width: 28%;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      &:last-child {
        border: none; /* remove border ? */
      }
    }
  }
  .questainnaire-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .question-list-wrapper {
      width: 90%;
      margin: auto;
      ol {
        list-style: decimal;
        ::marker {
          color: #636c73;
        }
      }
    }
  }
}
