@use '../../../configs/styles/defaultStyle.scss' as default;

.no-show-confirmation {
  padding: 1.5rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  max-width: 50vw;

  button {
    margin: 1rem 0;
  }
}

.mark-noshow-modal-progress-button {
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 999px;
  width: 75%;
  overflow: hidden;
  position: relative;
  color: white;
  font-weight: 600;
  background-color: default.$primary-color;

  &:disabled {
    background-color: #cfcbc7;
  }

  > .noshow-text {
    z-index: 2;
    position: relative;
  }

  &::before {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background-color: default.$primary-color;
    border-radius: 6px;
    color: white;
  }

  &.progress::before {
    animation: progress 5s linear forwards;
  }

  @keyframes progress {
    0% {
      left: -100%;
    }
    100% {
      left: 0%;
    }
  }
}
