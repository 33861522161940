.reschedule-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 545px;
  min-height: 500px;
  gap: 20px;
  padding: 16px 24px;
  width: 40vw;
  height: 78vh;
  .duration-category {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    .session-button {
      flex-grow: 1;
    }
  }
  .time-slots {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    margin-top: 10px;
    .fix-time-slot-container {
      padding: 5px 0;
      height: 100px;
      overflow-y: auto;
    }
  }
  .buttton-wrapper {
    padding-bottom: 16px;
  }
}
