@use '../../../configs/styles/defaultStyle.scss' as default;

.row-container {
  width: 100%;
  min-height: fit-content;
  box-sizing: border-box;
  max-height: 1000px;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid default.$divider-color;

  .day-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.5rem;
  }

  .timeslot-item-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;

    .summary {
      margin-bottom: 24px;
    }

    .location-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      background-color: #f9f9f9;
      border-radius: 0.5rem;
      padding: 0.75rem 1.5rem;
      width: fit-content;
    }

    .location-name {
      font-size: 14;
      width: 100px;
      color: default.$secondary-color;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .time-ranges-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      img.remove {
        cursor: pointer;
        z-index: 2;
      }
    }
  }

  .row-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;

    .select-box {
      height: auto;
      border-radius: 0.5rem;
      border: 1px solid default.$primary-color;
      padding: 0;
      .select-placeholder span {
        color: default.$primary-color !important;
        font-size: 12px !important;
        font-weight: 500 !important;
      }

      .dropdown-item {
        &.selected {
          background-color: rgb(233, 236, 238);
        }
      }
    }
  }
}
