@use '../../../configs/styles/defaultStyle.scss' as default;

$data-list-column-spec: 1fr 1fr 1fr 2fr;

.billings-container {
  height: 100%;
  width: auto;
  margin: 36px;

  .billing-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid default.$divider-color;
    padding-bottom: 10px;

    .main-heading {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .action-container {
      margin-right: 67px;
    }
  }

  .clients-content-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .search-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 1rem;
      align-items: center;
      margin: 19px 0px;

      .search-bar {
        width: 180px;
      }
    }

    .client-data-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 90px;

      .client-data {
        height: 515px;
        width: 100%;
        box-sizing: border-box;

        .list-headings {
          width: 100%;
          height: 3rem;
          box-sizing: border-box;
          display: grid;
          background-color: #fbfcfe;
          grid-template-columns: $data-list-column-spec;
          border: 1px solid #dde4f0;
          border-radius: 8px;
          margin-bottom: 1rem;
          padding-left: 1rem;

          .heading-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            box-sizing: border-box;
            column-gap: 1rem;
          }
        }
        .list-wrapper {
          border-radius: 8px;
          border: 1px solid #dde4f0;
        }
        .clients-list {
          background-color: default.$main-content-background-color;
          width: 100%;
          overflow-y: auto;
          height: 40vh;
          border-bottom: 1px solid #dde4f0;
          box-sizing: border-box;
          border-radius: 8px;
          border-end-start-radius: 1px;
          border-end-end-radius: 1px;
          &.fixed {
            overflow-y: unset;
            width: 100%;
            position: relative;
            height: fit-content;
            border-bottom: none;
            border-end-start-radius: 8px;
            border-end-end-radius: 8px;
            border-start-start-radius: 1px;
            border-start-end-radius: 1px;
          }

          .billing-list-item {
            width: 100%;
            box-sizing: border-box;
            height: 4.5rem;
            border-bottom: 1px solid #dde4f0;
            display: grid;
            grid-template-columns: $data-list-column-spec;
            column-gap: 1rem;
            padding: 1rem;
            user-select: none;

            div {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 0.25rem;
            }

            .last-row {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              gap: 0.25rem;
            }
          }
        }
        .additional-pay-details {
          width: 100%;
          padding: 1rem;
          box-sizing: border-box;
          height: 4.5rem;
          border-bottom: 1px solid #dde4f0;
          .bonus-details {
            display: grid;
            grid-template-columns: 3fr 2fr;
            column-gap: 3rem;
            padding: 5px 0;
          }
        }
      }
    }
  }

  .billings-content-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 1079px;

    .search-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin: 23px 0px;

      .search-bar {
        width: 280px;
      }
    }

    .billing-data-container {
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 90px;
      overflow-y: auto;
      .billing-data {
        height: 515px;
        width: 100%;
        background-color: default.$main-content-background-color;
        border-radius: default.$main-content-border-radius;
        padding: 28px 33px;
        box-sizing: border-box;
        overflow-y: auto;

        table {
          border-collapse: collapse;
          table-layout: fixed;
          width: 100%;
          overflow: auto;

          tr {
            th {
              text-align: left;
              padding-bottom: 12px;
            }

            th.date,
            td.date {
              width: 25%;
              white-space: nowrap;
            }

            th.sessions-count {
              width: 20%;
              white-space: nowrap;
            }

            td.sessions-count {
              width: 60%;
              white-space: nowrap;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;
              height: 56px;
            }
            tr.description,
            td.description {
              width: 100%;
            }
          }

          tr.data {
            height: 56px;
            border-top: 1px solid default.$divider-color;
          }
        }
      }
    }
  }
}
