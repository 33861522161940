.assignments-container {
  height: 100%;
  width: auto;
  margin: 36px;
  position: relative;

  .header-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
    padding-right: 32px;
    box-sizing: border-box;

    .assignment-tabs-section {
      width: 100%;
      box-sizing: border-box;
      padding-top: 14px;
      margin-bottom: 24px;
    }
  }
}
