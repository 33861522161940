.goals-card-container {
  .no-result {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tabs-container {
    .tab-headers {
      .tab-header {
        span:last-child {
          &.typography {
            &.secondary {
              color: #121212;
            }
          }
        }
      }
    }
  }
}
