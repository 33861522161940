@use '../../../configs/styles/defaultStyle.scss' as default;

.calenderview-container {
  width: 50%;
  min-height: 620px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  padding: 0px 1rem 0px 1rem;
  border-right: 1px solid default.$divider-color;
  box-sizing: border-box;

  .controls {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    margin-bottom: 40px;
    margin-top: 35px;
    padding: 0 5rem;

    .control-item {
      height: 30px;
      width: 30px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .calenderview {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 70px);
    grid-template-rows: repeat(6, 65px);
    justify-content: center;
    align-items: center;
    justify-items: center;
    padding-bottom: 35px;
    border-bottom: 1px solid default.$divider-color;

    .day {
      height: 46px;
      width: 46px;
      border-radius: 50%;
      background-color: transparent;
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background-color: default.$hover-border-color;
        transform: scale(1.2);
      }

      &.selected {
        border: 2px solid default.$primary-color;
      }

      .item {
        height: 42px;
        width: 42px;
        border-radius: 50%;
        background-color: #eaebec;
        display: flex;
        justify-content: center;
        align-items: center;

        span.number {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: default.$primary-color;

          &.disabled {
            opacity: 0.2;
          }

          &.today {
            color: #ffffff;
          }
        }

        &.disabled {
          opacity: 0.2;
        }

        &.scheduled {
          background-color: #99ddbd;
          animation: popInCalenderIndicator 400ms cubic-bezier(0.19, 1, 0.22, 1);
        }

        &.today {
          background-color: default.$primary-color;
        }

        &.on-leave {
          background-color: #ffd9cf;
          animation: popInCalenderIndicator 400ms cubic-bezier(0.19, 1, 0.22, 1);
        }

        &.inperson-virtual {
          background-color: none;
          background-image: -webkit-linear-gradient(
            135deg,
            #dbbfe5 50%,
            #d1e6fa 50%
          );
        }

        &.inperson-onsite {
          background-color: none;
          background-image: -webkit-linear-gradient(
            135deg,
            #f9ef9680 50%,
            #d1e6fa 50%
          );
        }

        &.virtual-onsite {
          background-color: none;
          background-image: -webkit-linear-gradient(
            135deg,
            #f9ef9680 50%,
            #dbbfe5 50%
          );
        }

        &.virtual-inperson-onsite {
          background-color: none;
          background-image: -webkit-linear-gradient(
            135deg,
            #d1e6fa 0,
            #d1e6fa 35%,
            #f9ef9680 35%,
            #f9ef9680 65%,
            #dbbfe5 65%,
            #dbbfe5 100%
          );
        }

        &.virtual {
          background-color: #dbbfe5;
        }

        &.in-person {
          background-color: #d1e6fa;
        }

        &.onsite {
          background-color: #f9ef9680;
        }
      }
    }
  }

  .indicators {
    height: 90px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .indicator {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 20px;

      .color-circle {
        width: 19px;
        height: 19px;
        border-radius: 50%;
        margin-right: 4px;
        border: none;
      }
    }
  }
}

@keyframes popInCalenderIndicator {
  0% {
    width: 0px;
    height: 0px;
  }
  100% {
    width: 100%;
    height: 100%;
  }
}
