.case-note-wrapper {
  width: 100%;
  padding: 24px 40px 40px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  box-sizing: border-box;
  gap: 28px;

  .case-note-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      .header-info-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 28px;
        &.extra-padding {
          padding-right: 18px;
        }
      }
      .form-label {
        color: #3e445b;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        > span {
          color: #828282;
          font-weight: 400;
        }
      }
      .session-note-wrapper,
      .technique-wrapper,
      .followup-wrapper,
      .additional-recommendation-wrapper {
        width: 100%;
      }
      .technique-wrapper {
        .select-box {
          margin-top: 14px;

          .select-placeholder {
            justify-content: space-between;
            > span {
              width: 80%;
            }
          }
          .v2-dropdown {
            width: 100%;
            .dropdown-item {
              height: 3rem;
              > span {
                width: 80%;
              }
            }
          }
        }
      }
      .followup-share-wrapper {
        .shared-followup {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .followup {
            width: 85%;
          }
          .custom-radio-groups {
            width: 15%;
          }
        }
      }
      .editor-wrapper {
        background: #fbfcfe;
        border-radius: 8px;
        border: 1px solid #d0d5dd;
        padding: 17px 21px;
        margin-top: 14px;
        .custom-editor-height {
          .editor-input-wrapper {
            height: 1.5rem;
            overflow-y: hidden;
            .mentions {
              font-size: 14px;
            }
          }
        }
        .custom-editor-style {
          .editor-input-wrapper {
            min-height: 50px;
            overflow-y: auto;
            .mentions {
              font-size: 14px;
            }
          }
        }
      }
      .progress-wrapper,
      .recommendation-wrapper,
      .followup-share-wrapper {
        width: 100%;
        padding: 24px;
        border-radius: 8px;
        border: 1px solid #d0d5dd;
        padding-right: 18px;
      }
      .progress-wrapper {
        .custom-radio-groups {
          gap: 35px;
          .radio-item {
            label {
              font-weight: 400;
            }
          }
        }
      }
      .submit-button {
        width: 285px;
        height: 44px;
        background-color: #263e58;
        color: white;
        border-radius: 100px;
        border: none;
        cursor: pointer;
        margin: 30px auto;
        display: block;
        font-weight: 600;
      }
    }
  }
}
