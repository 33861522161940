@use '../../../../../configs/styles/mixins.scss';

.menu-contents {
  @include mixins.flex-col;
  position: absolute;
  background-color: #fff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .menu-item {
    @include mixins.selectable;
    box-sizing: border-box;
    padding: 1rem 1.5rem;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  }
}
