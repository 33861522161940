.assignments-section {
  width: 100%;
  .filters-and-search {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0px;

    .filters {
      display: flex;
      gap: 1rem;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .search {
      width: 30%;
    }
  }

  .assignments-listing {
    width: 100%;
    height: calc(100vh - calc(90px + 92px));
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 0fr));
    grid-auto-rows: minmax(min-content, max-content);
    gap: 1rem;
    position: relative;

    .empty-assignments {
      position: absolute;
      left: 50%;
      top: 3rem;
      transform: translateX(-50%);
    }

    .bottom-element {
      width: 100%;
      height: 0.5rem;
    }
  }

  .goto-top-container {
    position: fixed;
    bottom: 20%;
    right: 3%;
  }
}
