@use '../../../configs/styles/defaultStyle.scss' as default;
.msg-template-footer {
  width: 100%;
  height: 72px;
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  background-color: #f5f5f5;
  border-radius: 0 0 8px 8px;
  justify-content: space-between;
  border-top: 1px solid default.$default-card-border-color;
  .footer-content-left {
    display: flex;
    gap: 8px;
    width: 80%;
  }
}
