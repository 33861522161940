@use '../../../configs/styles/defaultStyle.scss' as default;
.leave-marker-parent {
  width: 533px;
  padding: 30px 20px 30px 30px;
  .marker-tooltip {
    width: 40%;
  }
  .marker-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    margin-top: 32px;
    .leave-marker-body {
      display: flex;
      flex-direction: column;
      gap: 18px;
      .date-selection-group {
        display: flex;
        gap: 0.5rem;
        .date-selection {
          display: flex;
          width: 50%;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          .date-picker-style {
            width: 80%;
            margin-top: 0;
            img {
              display: none;
            }
          }
        }
      }
    }
    .leave-marker-footer {
      border-top: 1px solid default.$divider-color;
      .leave-marker-footer-content {
        display: flex;
        flex-direction: column;
        padding-top: 1.5rem;
        gap: 1rem;
      }
    }
  }
}
