.sessions-container {
  height: 100%;
  width: auto;
  margin: 36px;

  .heading-container {
    margin-bottom: 42px;
  }

  .interactions-container {
    height: 620px;
    width: 75vw;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
