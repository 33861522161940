.assignment-card-container {
  width: 33%;
  padding: 8px;
  display: flex;
  border-radius: 8px;
  border: 1px solid rgba(221, 219, 216, 0.32);
  background: #fff;
  gap: 12px;
  padding-bottom: 9px;
  cursor: pointer;
  .assignment-card-img {
    width: 40%;
    img {
      height: 85px;
      width: 132px;
      border-radius: 4px;
    }
  }
  .assignment-card-content {
    width: 60%;
    display: block;
    flex-direction: column;
    gap: 7px;
    max-height: 85px;
    .assignment-card-title {
      height: 33px;
      display: flex;
      align-items: center;
    }
    .tags-container {
      row-gap: 4px;
      align-items: center;
      height: 32px;
    }
    span {
      &.typography {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    .tags-style {
      height: 14px;
      min-width: 40px;
    }
    .assignment-card-button {
      display: flex;
    }
  }
}
