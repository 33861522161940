@use '../../../configs/styles/defaultStyle.scss' as default;
.template-header-container {
  display: flex;
  height: 50px;
  padding: 11px 16px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #a3a8ad;
  .left-header-wrapper {
    display: flex;
    gap: 4px;
  }
  .right-header-wrapper {
    display: flex;
    gap: 9px;
  }
}
