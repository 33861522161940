.parent-section {
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fbfcfe;
  &.show {
    visibility: visible;
  }
  .card-header {
    .card-close-control {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
      z-index: 10;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .card-body {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
    .heading-section {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;
      img {
        filter: brightness(0) saturate(100%) invert(65%) sepia(10%)
          saturate(409%) hue-rotate(171deg) brightness(97%) contrast(87%);
      }
    }
    .leave-date-section {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #f1f7fd;
      border-radius: 8px;
      padding: 0.5rem;
      gap: 2rem;
      .date-item-wrapper {
        width: 50%;
        line-height: 20px;
      }
    }
    .card-img {
      width: 89px;
      height: 89px;
    }
    .card-action {
      display: inline-flex;
      gap: 0.5rem;
      cursor: pointer;
      img {
        cursor: pointer;
        filter: brightness(0) saturate(100%) invert(49%) sepia(54%)
          saturate(7490%) hue-rotate(202deg) brightness(99%) contrast(97%);
      }
    }
  }
}
