@use '../../../configs/styles/defaultStyle.scss' as default;

// todo remove this unwanted styles once UAT is done

// .daily-override-container {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   align-items: flex-start;

//   .tab-info-container {
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     align-items: flex-start;
//     margin-bottom: 44px;
//   }

//   .daily-override-controls {
//     width: 100%;
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-start;
//     align-items: flex-start;

//     .calender-view {
//       display: block;
//       width: 50%;
//     }

//     .daily-overrides-edit {
//       display: flex;
//       flex-direction: column;
//       justify-content: flex-start;
//       align-items: flex-start;
//       box-sizing: border-box;
//       padding: 1.5rem;
//       width: 50%;
//       position: relative;
//       min-height: 60vh;

//       .edit-header {
//         margin-bottom: 2rem;
//         display: flex;
//         flex-direction: row;
//         justify-content: space-between;
//         align-items: center;
//         width: 100%;

//         .custom-tag {
//           height: 34px;
//         }
//       }
//       .slots-header {
//         width: 100%;
//         display: grid;
//         grid-template-columns: 2fr 3.5fr 1fr;
//         justify-content: flex-start;
//         align-items: flex-start;
//         gap: 0.5rem;
//         margin-bottom: 1rem;
//         div {
//           display: grid;
//           grid-template-columns: 1fr 1fr;
//         }
//       }
//       .slots-editor {
//         display: flex;
//         flex-direction: column;
//         justify-content: flex-start;
//         align-items: flex-start;
//         gap: 1rem;
//         margin-bottom: 60px;
//         width: 100%;

//         .added-slots {
//           width: 100%;
//           display: flex;
//           flex-direction: column;
//           justify-content: flex-start;
//           align-items: flex-start;
//           gap: 1rem;

//           .slot-item {
//             display: grid;
//             width: 100%;
//             grid-template-columns: 1fr 2fr 1fr;
//             align-items: center;
//             gap: 1rem;
//             margin-bottom: 1rem;

//             .time-range-picker-container {
//               gap: 1rem !important;
//             }

//             .location-container {
//               display: flex;
//               align-items: center;
//               justify-content: space-between;
//               gap: 1rem;
//               background-color: white;
//               border: 1px solid #d0d5dd;
//               border-radius: 0.5rem;
//               height: 2.75rem;
//               padding: 0.75rem 1.25rem;
//             }

//             .location-name {
//               font-size: 14;
//               width: 100px;
//               color: default.$secondary-color;
//               text-overflow: ellipsis;
//               overflow: hidden;
//               white-space: nowrap;
//             }
//             img.remove-slot {
//               justify-self: center;
//               cursor: pointer;
//               min-width: 18px;
//             }
//           }
//         }

//         .row-actions {
//           .select-box {
//             height: auto;
//             border-radius: 100px;
//             border: 1px solid default.$primary-color;
//             background-color: white;
//             padding: 0;
//             .select-placeholder span {
//               color: default.$primary-color !important;
//               font-size: 12px !important;
//               font-weight: 500 !important;
//             }

//             .dropdown-item {
//               &.selected {
//                 background-color: rgb(233, 236, 238);
//               }
//             }
//           }
//         }
//       }

//       .edit-confirmations {
//         position: absolute;
//         bottom: 0px;
//         width: 460px;
//         display: flex;
//         flex-direction: row;
//         justify-content: center;
//         align-items: center;
//         gap: 1rem;
//       }
//     }
//   }
// }

.daily-override-container {
  width: 100%;
  .daily-override-controls {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 10px;
    .daily-overrides-edit {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1.5rem;
      .edit-header {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .custom-tag {
          height: 34px;
        }
      }
      .slots-header {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 2rem;
        padding: 2rem 0 0;
        .location-header,
        .timerange-header {
          display: flex;
        }
        .location-header {
          width: 27%;
        }
        .timerange-header {
          gap: 8.5rem;
          width: fit-content;
        }
      }
      .slots-editor {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1rem;
        margin-bottom: 60px;
        width: 100%;

        .added-slots {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 1rem;

          .slot-item {
            display: flex;
            width: 100%;
            align-items: center;
            gap: 2rem;
            margin-bottom: 1rem;

            .location-container {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 1rem;
              background-color: white;
              border: 1px solid #d0d5dd;
              border-radius: 0.5rem;
              height: 2.75rem;
              padding: 0.5rem;
              width: 27%;
              .location-name {
                font-size: 14;
                width: 100px;
                color: default.$secondary-color;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
            .timerange-items {
              display: flex;
              width: 60%;
              gap: 1rem;

              .remove-slot {
                justify-self: center;
                cursor: pointer;
                min-width: 18px;
              }
              .time-range-picker-container {
                gap: 2rem !important;
              }
            }
          }
        }
        .row-actions {
          .select-box {
            height: auto;
            border-radius: 100px;
            border: 1px solid default.$primary-color;
            background-color: white;
            padding: 0;
            .select-placeholder span {
              color: default.$primary-color !important;
              font-size: 12px !important;
              font-weight: 500 !important;
            }
            .dropdown-item {
              &.selected {
                background-color: rgb(233, 236, 238);
              }
            }
          }
        }
      }

      .edit-confirmations {
        bottom: 0px;
        width: 460px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;
      }
    }
    .calender-view {
      display: block;
      width: 100%;
      .controls {
        width: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        margin-bottom: 40px;
        margin-top: 35px;
      }
      .calenderview {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(7, minmax(40px, 1fr));
        border-bottom: 1px solid #e9eaeb;
        z-index: 1;
        .day {
          height: 35px;
          width: 35px;
          .item {
            height: 32px;
            width: 32px;
          }
        }
      }
      .indicators {
        height: 90px;
        width: max-content !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px !important;
      }
    }
  }
}

@media (max-width: 1000px) {
  .daily-override-container {
    width: 100%;
    .daily-override-controls {
      display: flex;
      flex-direction: column;
      .daily-overrides-edit {
        padding: 0.5rem;
        gap: 0.5rem;
        .slots-header {
          padding: 0;
          gap: 1rem;
          .timerange-header {
            width: 60%;
            gap: 7.5rem;
          }
        }
        .slots-editor {
          .added-slots {
            .slot-item {
              gap: 1rem;
              .timerange-items {
                gap: 0.5rem;
                .time-range-picker-container {
                  gap: 1rem !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.onsite-times-warning {
  background-color: #f9ef9680;
  border: 1px solid #fdcd84;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  margin: 0 0.5rem;

  > .onsite-header {
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 1em;
  }

  > .onsite-description {
    font-size: 0.75em;
  }
}
