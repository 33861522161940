@use '../../../configs/styles/defaultStyle.scss' as default;
.msg-template-container {
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid default.$default-card-border-color;
  box-sizing: border-box;
  &.enabledEditor {
    background: rgba(237, 242, 247, 0.5);
  }

  .msg-template-body {
    height: 122px;
    padding: 16px;
    margin-bottom: 16px;
  }
}
