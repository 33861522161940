.late-percentage-wrapper {
  padding: 1.947rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;
  min-width: 500px;
  width: 25vw;
  .late-percentage-body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
    .late-cancelled-session-note {
      padding: 1rem;
    }
    .late-percentage-metric {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      :first-child {
        .typography {
          padding: 1rem 0;
        }
      }
    }
    .sub-header {
      padding: 1rem 1.5rem 0 1.5rem;
      .lcp-subheader-bold {
        font-weight: 600;
      }
    }
    .late-percentage-actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
    }
  }
}
