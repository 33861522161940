@use '../../../../configs/styles/defaultStyle.scss' as default;

.clients-wrapper-v2 {
  margin: 36px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: calc(100% - 72px);
  .clients-header-wrapper {
    padding: 0;
  }
  .filter-search-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
    .search-bar {
      width: 70%;
    }

    .filter-wrapper {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
  }
  .client-data-wrapper {
    box-sizing: border-box;
    overflow-y: auto;
    width: 100%;
    border: 1px solid default.$divider-color;
    border-radius: 8px;
  }

  .pagination-container {
    margin-top: 0.5rem;
  }
}

@media (max-width: 1024px) {
  .clients-wrapper {
    margin: 0;
    width: 100%;
  }
}
