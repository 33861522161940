@use '../../../configs/styles/defaultStyle.scss' as default;

.schedule-timeslot-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 70vw;
  .banner-container {
    margin-top: 0;
    margin-bottom: 25px;
  }

  &.hide {
    display: none;
  }

  .tab-info-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 44px;
  }

  .working-hours-data {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-direction: column;
    display: block;

    .slots-header-container {
      background-color: #fbfcfe;
      border: 1px solid #e9eaeb;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      height: 50px;
      margin-bottom: 8px;
      width: 100%;
      gap: 100px;
      border-radius: 8px;

      .table-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &:first-child {
          margin-left: 1rem;
        }
      }
    }

    .slots-container {
      border: 1px solid #dde4f0;
      border-radius: 8px;
      overflow: auto;
    }
  }
}
