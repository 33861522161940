@use '../../../configs/styles/defaultStyle.scss' as default;

.scheduled-sessions-container {
  width: 50%;
  min-height: 620px;
  background-color: #fff;

  .header {
    height: 75px;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid default.$divider-color;
    position: relative;

    img.menu-icon {
      position: absolute;
      top: 2.15rem;
      right: 2.15rem;
      cursor: pointer;
    }
  }

  .scheduled-agenda {
    height: 400px;
    width: 100%;
    overflow-y: auto;

    .empty-container {
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .card-wrapper {
      padding: 16px;
    }

    .item {
      display: flex;
      flex-direction: column;
      padding: 16px;
      border-bottom: 1px solid default.$divider-color;

      &.message-type {
        justify-content: center;
      }

      .info-container {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-right: 0.5rem;
        Button {
          padding: 0;
          &.raised {
            justify-content: flex-end;
            box-shadow: none;
            background-color: #fff;
          }
          img {
            margin-left: 5px;
            filter: brightness(0) saturate(100%) invert(8%) sepia(8%)
              saturate(3817%) hue-rotate(167deg) brightness(94%) contrast(85%);
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .action-container {
        display: grid;
        grid-template-columns: 4fr 4fr 0.25fr;
        align-items: center;
        margin-top: 1rem;
        gap: 1.5rem;
        button {
          width: 100%;
        }
      }
    }
  }
}

.time-clock-container,
.location-info-container {
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  img {
    margin-right: 0.5rem;
  }
}
.tag-container {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 10px;
  margin-top: 10px;

  .tag {
    height: 24px;
    padding: 3px 13px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    width: max-content;
    align-items: center;

    &.normal {
      background-color: rgba(26, 38, 49, 0.1);
    }

    &.blue {
      background-color: #d7e8fc;
    }

    &.pill {
      background-color: #428bb5;
      border-radius: 100px;
      height: 20px;
    }
  }
}
