.assignment-note {
  height: 310px;
  width: 500px;
  position: relative;

  .notes-contents {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 1rem;

    .note-header {
      margin-bottom: 32px;
    }

    .note-enter {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      gap: 12px;

      .note-field {
        width: 100%;
        height: 100px;
      }
    }
  }

  .note-confirmation {
    position: absolute;
    height: 73px;
    width: 100%;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fbfcfe;
    border-top: 1px solid #dde4f0;
    box-sizing: border-box;
    padding: 16px 24px;
  }
}
