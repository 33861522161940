// * ca -> short form for custom assignments
.create-ca-container {
  width: min(500px, 30vw);
  height: 80vh;
  box-sizing: border-box;
  position: relative;

  .create-ca-configs {
    padding: 1rem 1rem 0rem 1rem;
    .ca-header {
      margin-bottom: 32px;
    }

    .ca-form {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 26px;
      box-sizing: border-box;
      padding-bottom: 80px;

      .ca-form-field {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        gap: 15px;

        .field-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .field-header-item {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 0.5rem;

            &.label {
              gap: 1px;
            }
          }
        }

        .file-upload-drop-zone {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 160px;
          width: 100%;
          background-color: #fbfcfe;
          border: 1px solid #dde4f0;
          border-radius: 4px;
          gap: 0.5rem;
          box-sizing: border-box;
          padding: 0px 25%;

          &.select-file {
            cursor: pointer;
          }

          &.uploaded-file {
            align-items: flex-start;
            padding: 0px 1rem;
          }

          input[type='file'] {
            visibility: hidden;
            position: absolute;
            z-index: -1;
          }
        }
      }
    }
  }
  .ca-action-container {
    position: absolute;
    height: 73px;
    width: 100%;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fbfcfe;
    border-top: 1px solid #dde4f0;
    box-sizing: border-box;
    padding: 16px 24px;
  }
}
