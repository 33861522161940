@use '../../../configs/styles/mixins.scss';

.billing-details {
  box-sizing: border-box;
  padding: 32px 38px;

  .breakdown-container {
    @include mixins.flex-col;
    width: 100%;
    padding: 1.5rem;
    box-sizing: border-box;
    background-color: #fbfcfe;
    border: 1px solid #dde4f0;
    border-radius: 8px;

    .breakdown-header {
      @include mixins.flex-col;
      width: 100%;
      padding-bottom: 1.5rem;
      border-bottom: 0.5px solid #c8cbd9;
    }

    .breakdowns {
      @include mixins.flex-col;
      width: 100%;
      gap: 1rem;
      margin-top: 1.5rem;
      border-bottom: 0.5px solid #c8cbd9;
      padding-bottom: 30px;

      .breakdown-details {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1rem;
      }
    }

    .total-breakdowns {
      @include mixins.flex-col(flex-start, flex-end);
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}
