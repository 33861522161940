.goals-card-container {
  .tabs-container {
    .tab-headers {
      .tab-header {
        span:last-child {
          &.typography {
            &.secondary {
              color: #121212;
            }
          }
        }
      }
    }
  }
}
