.inbound-links {
  color: #48515a !important;
  a {
    &:hover {
      color: #00f;
    }
    &:visited {
      color: #48515a;
    }
  }
}
.outbound-links {
  a {
    color: #fff !important;
    &:hover {
      color: #9e9eff !important;
    }
  }
}
