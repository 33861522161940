.select-location-popup {
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;

  .popup-header {
    padding: 1.5rem 2rem;
  }

  .location-select {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
    padding: 1rem;

    .checkbox-wrapper {
      section {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        gap: 2rem;
        align-items: center;
        justify-content: space-between;
        label {
          color: #1f384c;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .confirm-locations-container {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.25rem 2rem;
    background-color: #fbfcfe;
    border-top: 1px solid #dde4f0;
  }

  .slot-select {
    width: 100%;
    margin-top: 2rem;
  }

  .action-container {
    margin-top: 100px;
    width: 100%;
  }
}
