@use '../../../configs/styles/defaultStyle.scss' as default;
.settings-container {
  width: 100%;
  padding: 24px 40px 40px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  box-sizing: border-box;
  .settings-body {
    height: 90%;
    display: flex;
    flex-direction: column;
    .settings-header {
      width: 100%;
      height: 3rem;
      box-sizing: border-box;
      border-radius: 8px;
      border: 1px solid #dde4f0;
      background: #fbfcfe;
      margin-top: 2rem;
      display: flex;
      align-items: center;
      padding: 1rem;
      gap: 2.3rem;
      .trigger-section-header {
        width: 27%;
      }
      .template-section-header {
        width: 62%;
      }
    }
    .trigger-template-section {
      display: flex;
      gap: 1rem;
      padding: 16px 0;
      box-sizing: border-box;
      .trigger-section {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        overflow-y: scroll;
      }
      .template-section {
        border-radius: 8px;
        width: 70%;
      }
    }
  }
}
.loader-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
