.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  cursor: pointer;

  input {
    height: 0px;
    width: 0px;
    opacity: 0;
  }

  span.slider {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    transition: 0.4s;
    background-color: #e6edf2;
    border-radius: 20px;
    user-select: none;

    &::before {
      position: absolute;
      content: '';
      height: 16px;
      width: 16px;
      left: 2px;
      bottom: 2px;
      transition: 0.4s;
      background-color: #fff;
      border-radius: 50%;
    }
  }

  input:checked + .slider::before {
    transform: translateX(14px);
    background-color: #e6edf2;
  }

  input:checked + .slider {
    background-color: #1a2631;
  }
}
.switch-disable {
  cursor: not-allowed;
  opacity: 0.7;
}
