.appointments-card {
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  padding: 20px 13px 20px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 8px;

  .card-contents {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;

    .cancel-appointments {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      user-select: none;
      margin-top: 1.5rem;
    }
  }

  img {
    object-fit: cover;
  }
}
