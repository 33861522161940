.trait-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  .trait-left-content {
    width: 75%;
  }
  .trait-score-content {
    display: flex;
    width: 25%;
    padding: 10px;
    justify-content: space-evenly;
    align-items: center;
    .trait-progress-indicator-wrapper {
      display: inline-flex;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      &.increasing {
        color: #0aa65d;
      }
      &.decreasing {
        color: #d96b6b;
      }
    }
  }
  .trait-control-content {
    width: 24px;
    height: 24px;
    margin: auto 0;
    cursor: pointer;
    .icons {
      width: 24px;
      height: 24px;
      margin: auto;
      margin: auto;
      color: #263e58db;
    }
  }
  .hero-dimension-wrapper {
    width: 545px;
    padding: 54px 32px;
    height: 100%;
    overflow-y: scroll;
  }
}
