.leave-note-wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  width: 42vw;
  height: 45vh;
  min-height: 360px;
  .leave-note-editor {
    border-radius: 4px;
    border: 1px solid #dde4f0;
    background: #fbfcfe;
    padding: 7px 12px;
  }
}
