.multi-select-container {
  box-sizing: border-box;
  position: relative;
  border-radius: 0.25rem;
  // min-width: 11rem;
  height: 2.5rem;
  background-color: #fbfcfe;
  border: 1px solid #dde4f0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .placeholder-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0.75rem 1rem;
    &.wrapSelectedOpt {
      max-width: 210px;
      .typography {
        text-wrap: nowrap;
      }
    }
  }

  .select-list {
    position: absolute;
    top: 0px;
    left: 0px;
    max-height: 280px;
    min-height: max-content;
    // overflow: auto;
    transform: translateY(100%);
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0px 0px 24px rgba(26, 38, 49, 0.08);
    border-radius: 0.5rem;
    .search-wrapper {
      width: 90%;
      margin: 10px auto;
    }
    .options-wrapper {
      overflow: auto;
      min-height: max-content;
      width: 100%;
      .select-list-item {
        width: 100%;
        box-sizing: border-box;
        height: 2.25rem;
        padding: 0.75rem 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 0.75rem;
        background-color: transparent;
        border-bottom: 1px solid #dde4f0;
      }
    }
    .no-results {
      justify-content: center;
      height: 160px;
    }
  }
}
