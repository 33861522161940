.cancellation-wrappper {
  padding: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  min-width: 500px;
  width: 37vw;
  height: 70vh;
  .cancellation-body {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-bottom: 24px;
  }
  .other-reason-input {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 14px;
    font-weight: 400;
    height: 100px;
    font-family: 'Poppins';
    resize: none;
    &:focus {
      outline: none;
    }
  }
}
