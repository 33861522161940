.carousel-wrapper {
  width: 100%;
  display: flex;
  gap: 25px;
  .control-item {
    width: 24px;
    height: 24px;
    margin: auto 0;
    cursor: pointer;
    .icons {
      width: 24px;
      height: 24px;
      margin: auto;
      margin: auto;
      color: #263e58db;

      &.disabledIcon {
        color: #b0b0b04d;
        cursor: not-allowed;
      }
    }
  }
  .carousel-item {
    width: 94%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 25px;
  }
}
