.personal-insights-container {
  width: 100%;
  box-sizing: border-box;
  padding: 1.625rem 2.5rem;
  min-height: 100vh;
  padding-bottom: 100px;
  background-color: #fff;
  transform: none !important;
  .personal-insights-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .personal-insights-body {
    .personal-insights-upper {
      display: flex;
      gap: 24px;
      padding: 24px 0;
      align-items: center;
    }
    .personal-insights-lower {
      padding: 24px;
      border-radius: 8px;
      border: 1px solid #ececec;
      background: #fff;
      .recomendationHeader {
        padding-bottom: 22px;
      }
    }
  }
}
