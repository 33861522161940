.report-card-wrapper {
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(229, 239, 250, 0.6);
  background: rgba(215, 232, 252, 0.06);
  display: flex;
  padding: 15px 22px 16px 22px;
  margin-top: 24px;
  justify-content: center;
  align-items: center;
  .report-marks-section {
    width: 25%;
    border-right: 2px solid rgba(0, 0, 0, 0.1);
    padding-right: 20px;
    .progress-indicator-wrapper {
      display: inline-flex;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      &.increasing {
        color: #0aa65d;
      }
      &.decreasing {
        color: #d96b6b;
      }
    }
  }
  .report-description-container {
    width: 75%;
    padding-left: 2rem;
  }
  .report-control-item {
    width: 24px;
    height: 24px;
    margin: auto;
    cursor: pointer;
    .icons {
      width: 24px;
      height: 24px;
      margin: auto;
      margin: auto;
      color: #263e58db;
    }
  }
  .hero-dimension-wrapper {
    width: 545px;
    padding: 54px 32px;
    height: 100%;
    overflow-y: scroll;
  }
}
