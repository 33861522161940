.PHQ-container {
  // height: 466px;
  width: 35%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 8px;
  border: 1px solid #fff7eb;
  background: rgba(255, 247, 235, 0.1);
  padding: 24px 25px 88px 25px;
  align-items: center;
  .PHQ-header-container {
    width: 100%;
    text-align: left;
  }
  .meter-section {
    position: relative;
    width: 100%;
  }
  .PHQ-desc-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.1rem;
  }
}
