.goto-top {
  height: 48px;
  width: 47px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #dce0e4;
}
