.cancel-appointments-container {
  height: 75vh;
  width: 30vw;
  padding: 32px 26px 26px 26px;
  // position: relative;
  // overflow-x: hidden; // smooth animation

  img.close-popup {
    position: absolute;
    cursor: pointer;
    top: 26px;
    right: 26px;
  }

  .cancel-appointments-contents {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;

    .appointment-listing {
      height: 55vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow-y: auto;
      margin-top: 32px;
      gap: 8px;

      .empty-sessions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        height: 100%;
        width: 100%;
      }

      .appointment-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-height: 82px;
        border: 1px solid #dde4f0;
        background-color: #f1f4fb;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 18px 24px;

        .left {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 0.25rem;

          .time-labels {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 0.25rem;
          }
        }
      }
    }
  }
}
