.pi-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .dimension-chart {
    height: 385px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    .current-score {
      display: flex;
      align-items: center;
      gap: 8px;
      .score-icon-wrapper {
        font-size: 16px;
        font-weight: 500;
        display: inline-flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
        &.increasing {
          color: #0aa65d;
        }
        &.decreasing {
          color: #d96b6b;
        }
      }
    }
  }
  .score-desc {
    width: 60%;
    padding: 10px 0;
    justify-content: space-around;
    display: flex;
    gap: 20px;
    section {
      flex: 0 0 auto;

      width: 28%;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      &:last-child {
        border: none; /* remove border ? */
      }
    }
  }
  .assignment-section {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .overlay-assignment {
      width: 65%;
    }
  }
}
