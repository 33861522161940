.assignment-card {
  width: 18rem;
  overflow-y: auto;
  border: 1px solid #dde4f0;
  border-radius: 0.5rem;
  box-sizing: border-box;
  padding: 0.5rem 0.75rem 4rem 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  position: relative;
  background-color: #fbfcfe;

  &.custom {
    min-height: 324px;
  }

  .image-container {
    height: 150px;
    width: 100%;
    margin-bottom: 0.5rem;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }

  .assignment-tags {
    height: fit-content;
  }

  .assignment-action {
    width: 90%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1rem;
  }
}
