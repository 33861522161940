.pagination-wrapper {
  width: 100%;
  margin-top: 24px;
  border-radius: 8px;
  border: 1px solid #ececec;
  .pagination-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    padding: 24px;
    background: #fcfcfc;
    border-radius: 8px;
    .pagination-heading {
      width: 70%;
    }
    .pagination-control {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 2rem;
      width: 25%;
      .control-item {
        width: 24px;
        height: 24px;
      }
      .disabledIcon {
        color: #b0b0b04d;
        cursor: not-allowed;
      }
    }
  }
  .pagination-body {
    margin: 0 18px;
  }
}
