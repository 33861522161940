@use '../../../configs/styles/defaultStyle.scss' as default;
.custom-translation-editor-wrapper {
  width: 100%;
  border: 1px solid rgba(175, 109, 10, 0.32);
  border-radius: 8px;
  padding: 16px 16px 8px 16px;
  margin: 32px 0 18px 0;
  .custom-msg-editor-tabs-wrapper {
    padding: 1rem 0;
  }
  .translation-editor-container {
    border: 1px solid default.$default-card-border-color;
    border-radius: 8px;
    margin-top: 16px;
    &.enabledEditor {
      background: rgba(237, 242, 247, 0.5);
    }
    .translation-editor {
      padding: 16px;
    }
  }
}
.only-banner {
  padding-top: 32px;
}
