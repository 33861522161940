.consent-banner-container {
  display: flex;
  padding: 16px;
  justify-content: left;
  align-items: center;
  input[type='checkbox'] {
    border: 1px solid #d0d5dd;
    background: white;
    border-radius: 50%;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    &:checked {
      appearance: auto;
      clip-path: circle(50% at 50% 50%);
      background-color: #253a53;
    }
    &:hover {
      transform: scale(1.1);
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
  label {
    margin-left: 8px;
  }
}
