@use '../../../configs/styles/defaultStyle.scss' as default;

.schedule-container {
  height: 100%;
  width: auto;
  margin: 36px;
  @media (max-width: '1000px') {
    margin: 10px;
  }

  .heading-container {
    margin-bottom: 42px;
  }

  .content-container {
    width: 100%;
    height: 100%;
    margin-top: 38px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .tab-headers {
      width: fit-content;
      height: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .tab-heading {
        min-width: 194px;
        border-bottom: 1px solid default.$divider-color;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 12px;
        margin-bottom: 37px;
        cursor: pointer;
        transition: border ease-in-out 0.3s;

        &.selected {
          border-bottom: 1px solid default.$primary-color;
        }
      }
    }

    .schedule-tabs-container {
      width: 100%;
      height: auto;
      overflow: auto;
      background-color: transparent;
      @media (max-width: '1000px') {
        overflow: hidden;
      }
    }
  }
}
